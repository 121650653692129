import React from "react";
import { Navigate } from "react-router-dom";

import { DASHBOARD_URL, FORGOT_PASSWORD_URL, HOME_URL, LOGIN_URL, NOT_FOUND_URL, NOTIFICATION_URL, SUPPORT_URL, VIEW_SUPPORT_URL } from "../constants/urls";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Login from "../pages/Authentication/Login";
import NotFound from "../pages/Authentication/NotFound";
import Dashboard from "../pages/Dashboard";
import ManageNotifications from "../pages/ManageNotifications";
import ManageSupport from "../pages/ManageSupport";
import ViewSupport from "../pages/ManageSupport/ViewSuport";

const authProtectedRoutes = [
  { path: DASHBOARD_URL, component: <Dashboard /> },
  { path: HOME_URL, exact: true, component: <Navigate to={DASHBOARD_URL} /> },
  { path: NOTIFICATION_URL, component: <ManageNotifications /> },
  { path: SUPPORT_URL, component: <ManageSupport /> },
  { path: VIEW_SUPPORT_URL, component: <ViewSupport /> },
  { path: "*", component: <Navigate to={NOT_FOUND_URL} /> },
];

const publicRoutes = [
  { path: LOGIN_URL, component: <Login /> },
  { path: NOT_FOUND_URL, component: <NotFound /> },
  { path: FORGOT_PASSWORD_URL, component: <ForgotPassword /> },
];

export { authProtectedRoutes, publicRoutes };
