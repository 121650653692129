import React from 'react';
import loadingLogo from '../../assets/xzlenzImages/xzlenz-login.jpg'

export default function Loader() {
  return (
    <>
      <div className="loading">
        <div>
        </div>
        <img
          src={loadingLogo}
          alt="..."
          style={{
            width: "4rem",
            position: "relative",
            zIndex: 99999,
            left: "-6.5rem"
          }}
        />
      </div>
    </>
  )
}