import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import dhanushLogo from '../../assets/xzlenzImages/dhanushguard.png';
import reportingToolLogo from '../../assets/xzlenzImages/reporting-tool-logo.png';
import scanbitLogo from '../../assets/xzlenzImages/scanbitlogo.png';
import xeonpayLogo from "../../assets/xzlenzImages/xeonpayLogo.png";
import xzlenzLogo from '../../assets/xzlenzImages/xzlenzDark.png';

const CardItem = ({ logo, title, description, link, isActive }) => (
  <Col lg={3} className="pe-1 ps-1">
    {isActive ? (
      <Link to={link} target="_blank">
        <Card className="overflow-hidden mb-2">
          <div className="py-2 px-3 text-center">
            <img src={logo} alt={title} style={{ width: '11rem', height: '4rem', objectFit: 'contain' }} />
            <p className="mt-1 text-muted text-center" style={{ fontSize: '.7rem' }}>{description}</p>
            {/* <p className="text-success mb-0" style={{ fontSize: '.8rem' }}>Active</p> */}
          </div>
        </Card>
      </Link>
    ) : (
      <Card className="overflow-hidden mb-2 bg-light">
        <div className="py-2 px-3 text-center">
          <img src={logo} alt={title} style={{ width: '11rem', height: '4rem', objectFit: 'contain', opacity: 0.5 }} />
          <p className="mt-1 text-muted text-center" style={{ fontSize: '.7rem' }}>{description}</p>
          {/* <p className="text-danger mb-0" style={{ fontSize: '.8rem' }}>Inactive</p> */}
        </div>
      </Card>
    )}
  </Col>
);

export default function CompanyCard({ licenseData }) {
  const jwtToken = localStorage.getItem('token');

  const XZLENZ_URL = process.env.REACT_APP_XZLENZ_URL;
  const SCANBIT_URL = process.env.REACT_APP_SCANBIT_URL;
  const REPORTING_TOOL_URL = process.env.REACT_APP_REPORTING_TOOL_URL;
  const DHANUSH_GUARD_URL = process.env.REACT_APP_DHANUSH_GUARD_URL;
  const XEON_PAY_URL = process.env.REACT_APP_XEON_PAY_URL;

  // Map license data to product names and statuses
  const licenseStatusMap = licenseData.reduce((acc, license) => {
    acc[license.productName] = license.status === "ACTIVE";
    return acc;
  }, {});

  const cardsData = [
    {
      logo: xzlenzLogo,
      title: 'Xzlenz.',
      description: 'Auditing tool from cybercube',
      link: `${XZLENZ_URL}/?token=${encodeURIComponent(jwtToken)}`,
      productName: 'Xzlenz', // Match with licenseData productName
    },
    {
      logo: scanbitLogo,
      title: 'Scanbit.',
      description: 'Card Scanning tool from cybercube',
      link: SCANBIT_URL,
      productName: 'Scanbit',
    },
    {
      logo: reportingToolLogo,
      title: 'Reporting Tool.',
      description: 'Reporting tool from cybercube',
      link: REPORTING_TOOL_URL,
      productName: 'Reporting Tool',
    },
    {
      logo: dhanushLogo,
      title: 'Dhanush Guard.',
      description: 'Security tool from cybercube',
      link: `${DHANUSH_GUARD_URL}/get-login/${encodeURIComponent(jwtToken)}`,
      productName: 'Dhanush Guard',
    },
    {
      logo: xeonpayLogo,
      title: 'Xeon Pay Tool.',
      description: 'Payment gateway tool from cybercube',
      link: XEON_PAY_URL,
      productName: 'Xeon Pay',
    },
  ];

  return (
    <Col lg={12} className="mt-3">
      <Row>
        {cardsData.map((card, index) => (
          <CardItem
            key={index}
            logo={card.logo}
            title={card.title}
            description={card.description}
            link={card.link}
            isActive={licenseStatusMap[card.productName] || false} 
          />
        ))}
      </Row>
    </Col>
  );
}