import moment from "moment";
import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { addSupportCommentAPI } from '../../../apiConfig/apiCall';
import avatar8 from "../../../assets/images/users/avatar-8.jpg";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getSportTicketDetail } from "../../../slices/GetSupportTicketDetails/thunk";

const TicketDescription = ({ ticketDetails }) => {

    const dispatch = useDispatch();

    const IMAGE_URL = process.env.REACT_APP_API_IMAGE_URL;

    const [comment, setComment] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleCommentChange = (e) => setComment(e.target.value);
    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type.startsWith("image/")) {
            setImage(selectedFile);
        } else {
            toast.error("Please select a valid image file!");
            e.target.value = ""; // Reset file input
        }
    };

    const handlePostComment = async () => {
        if (!comment.trim() && !image) {
            alert("Either a comment or an image is required!");
            return;
        }

        if (image && !comment.trim()) {
            alert("Comment is required when uploading an image!");
            return;
        }

        const formData = new FormData();
        formData.append('id', ticketDetails.id);
        formData.append('comment', comment);
        if (image) {
            formData.append('image', image);
        }

        try {
            setLoading(true);
            const apiResponse = await addSupportCommentAPI(formData);
            if (apiResponse.status === 201) {
                toast.success("Comment added successfully");
                dispatch(getSportTicketDetail(ticketDetails.id));
                setComment(""); // Clear comment input
                setImage(null); // Clear file input
            } else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.error("Error posting comment:", error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <React.Fragment>
            <Col lg={9}>
                <Card>
                    <CardBody className="p-4">
                        <h6 className="fw-semibold text-uppercase mb-3">Ticket Discripation</h6>
                        <p className="text-muted mb-0">{ticketDetails.overview}</p>
                    </CardBody>
                    <CardBody className="p-4">
                        <h5 className="card-title mb-4">Comments</h5>

                        <SimpleBar style={{ height: "300px" }} className="px-3 mx-n3">
                            {ticketDetails.comments && ticketDetails.comments.length > 0 ? (
                                ticketDetails.comments.map((comment, index) => (
                                    <div key={index}
                                        className={`d-flex justify-content-${comment.ssoid === "super" ? "end" : "start"}`}>
                                        <div className="d-flex mb-4">
                                            <div>
                                                <img
                                                    src={comment.avatar || avatar8}
                                                    alt={`${comment.firstName} ${comment.lastName}`}
                                                    className="avatar-xs rounded-circle material-shadow"
                                                />
                                            </div>
                                            <div className="ms-3">
                                                <h5 className="fs-13">
                                                    {comment.firstName} {comment.lastName}
                                                    <small className="text-muted">
                                                        {" - "}
                                                        {moment(comment.date).isValid() ? moment(comment.date).format("DD MMM YYYY - hh:mmA") : "Invalid date"}
                                                    </small>
                                                </h5>
                                                <p className="text-muted">{comment.comment}</p>

                                                {comment.imagePath ? (
                                                    <div className="bg-light p-2" style={{ width: '5rem', }}>
                                                        <img
                                                            src={IMAGE_URL + comment.imagePath}
                                                            alt="Comment attachment"
                                                            className="img-fluid rounded material-shadow"
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-muted">No comments available.</p>
                            )}
                        </SimpleBar>

                        <form className="mt-3">
                            <Row className="g-3">
                                <Col lg={12}>
                                    <Label htmlFor="comment" className="form-label">Leave a Comment</Label>
                                    <div className="position-reltaive">
                                        <textarea
                                            className="form-control bg-light border-light"
                                            id="comment"
                                            rows="3"
                                            placeholder="Enter comment"
                                            value={comment}
                                            onChange={handleCommentChange}
                                            style={{ height: '80px' }}
                                        ></textarea>
                                        <i
                                            className="ri-attachment-line"
                                            style={{
                                                position: 'absolute',
                                                bottom: '.5rem',
                                                left: '1rem',
                                            }}
                                            onClick={() => document.getElementById('imageUpload').click()}
                                        ></i>
                                    </div>
                                </Col>
                                <Col lg={12} style={{ height: '.1px', width: '.1px', overflow: 'hidden' }}>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        id="imageUpload"
                                        className="form-control bg-light border-light"
                                        onChange={handleImageChange}
                                    />
                                </Col>
                                <Col lg={12} className="text-end">
                                    <Button
                                        className="btn btn-success"
                                        onClick={handlePostComment}
                                        disabled={loading}
                                    >
                                        {loading ? "Posting..." : "Post Comment"}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>

                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TicketDescription;