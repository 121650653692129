import React from 'react';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';

const TicketDetails = ({ ticketDetails }) => {
    return (
        <React.Fragment>
            <Col lg={3} className='pe-1 ps-1'>
                <Card>
                    <CardHeader>
                        <h5 className="card-title mb-0">Ticket Details</h5>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive table-card">
                            <Table className="table-borderless align-middle mb-0">
                                <tbody>
                                    <tr>
                                        <td className="fw-medium">Ticket</td>
                                        <td>#<span id="t-no">{ticketDetails.id}</span></td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Status</td>
                                        <td id="t-client">{ticketDetails.status}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Client</td>
                                        <td id="t-client">{ticketDetails.clientCompanyName}</td>
                                    </tr>
                                      <tr>
                                        <td className="fw-medium">Entry Date</td>
                                        <td id="t-client">{ticketDetails.entryDate}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TicketDetails;