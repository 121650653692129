import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import Error from './Error';
import { getClientLicenseList } from '../../slices/LicenseList/thunk';
import { getLoggedInUserDetails } from '../../slices/UserDeatils/thunk';

// Import images
import dhanushLogo from "../../assets/xzlenzImages/dhanushguard.png";
import reportingToolLogo from "../../assets/xzlenzImages/reporting-tool-logo.png";
import scanBitLogo from "../../assets/xzlenzImages/scanbitlogo.png";
import xeonpayLogo from "../../assets/xzlenzImages/xeonpayLogo.png";
import xzlenzLogo from "../../assets/xzlenzImages/xzlenzDark.png";

const WebAppsDropdown = () => {
  const dispatch = useDispatch();
  const { isLoading, isError, data } = useSelector(state => state.UserDetails);
  const { isLoading: licenseLoading, isError: licenseError, data: licenseData } = useSelector(state => state.LicenseList);

  const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
  const toggleWebAppDropdown = () => setIsWebAppDropdown(!isWebAppDropdown);

  const jwtToken = localStorage.getItem('token');

  const XZLENZ_URL = process.env.REACT_APP_XZLENZ_URL;
  const SCANBIT_URL = process.env.REACT_APP_SCANBIT_URL;
  const REPORTING_TOOL_URL = process.env.REACT_APP_REPORTING_TOOL_URL;
  const DHANUSH_GUARD_URL = process.env.REACT_APP_DHANUSH_GUARD_URL;
  const XEON_PAY_URL = process.env.REACT_APP_XEON_PAY_URL;

  const appDetails = [
    { logo: xzlenzLogo, name: 'Xzlenz', url: `${XZLENZ_URL}/?token=${encodeURIComponent(jwtToken)}` },
    { logo: scanBitLogo, name: 'Scanbit', url: SCANBIT_URL },
    { logo: reportingToolLogo, name: 'Reporting Tool', url: REPORTING_TOOL_URL },
    { logo: dhanushLogo, name: 'Dhanush Guard', url: `${DHANUSH_GUARD_URL}/get-login/${encodeURIComponent(jwtToken)}` },
    { logo: xeonpayLogo, name: 'Xeon Pay', url: XEON_PAY_URL },
  ];

  useEffect(() => {
    dispatch(getLoggedInUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (data?.clientCompanyId) {
      dispatch(getClientLicenseList(data.clientCompanyId));
    }
  }, [dispatch, data?.clientCompanyId]);

  if (isLoading || licenseLoading) return <Loader />;
  if (isError || licenseError) return <Error />;

  const getAppStatus = (appName) => {
    return licenseData?.some(license => license.productName === appName && license.status === 'ACTIVE');
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={isWebAppDropdown} toggle={toggleWebAppDropdown} className="topbar-head-dropdown ms-1 header-item">
        <DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
          <i className="bx bx-category-alt fs-22"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fw-semibold fs-15">Our Apps</h6>
              </Col>
            </Row>
          </div>
          <div className="p-2">
            <div className="row g-0">
              {appDetails.map((app, index) => (
                <Col lg={4} key={index}>
                  {getAppStatus(app.name) ? (
                    <Link className="dropdown-icon-item" target="_blank" to={app.url}>
                      <img src={app.logo} alt={app.name} />
                      <span>{app.name}</span>
                    </Link>
                  ) : (
                    <div className="dropdown-icon-item text-muted" style={{ pointerEvents: 'none', opacity: 0.5 }}>
                      <img src={app.logo} alt={`${app.name} Disabled`} />
                      <span>{app.name}</span>
                    </div>
                  )}
                </Col>
              ))}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default WebAppsDropdown;