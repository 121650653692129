import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { getSportTicketDetail } from '../../../slices/GetSupportTicketDetails/thunk';
import Section from './Section';
import TicketDescription from './TicketDescription';
import TicketDetails from './TicketDetails';
import Loader from '../../../Components/Common/Loader';
import Error from '../../../Components/Common/Error';
import { useSelector } from 'react-redux';


const ViewSuport = () => {

    const params = useParams();
    const ID = params.id;

    const dispatch = useDispatch();
    const { isLoading, isError, data } = useSelector(state => state.SupportTicketDetails);

    useEffect(() => {
        dispatch(getSportTicketDetail(ID));
    }, [dispatch]);

    if (isLoading) return <Loader />

    if (isError) return <Error />


    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={'View Support'} pageTitle={"Dashboard"} />
                    <Row>
                        <Section ticketDetails={data} />
                    </Row>
                    <Row>
                        <TicketDescription ticketDetails={data} />
                        <TicketDetails ticketDetails={data} />
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ViewSuport;