import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getLoggedInUserAPI } from "../apiConfig/apiCall";
import Loader from '../Components/Common/Loader';

export const AuthProtected = ({ children }) => {
  const token = localStorage.getItem('token');

  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  const getLoggedInUserDetails = async () => {
    try {
      const apiResponse = await getLoggedInUserAPI();
      if (apiResponse === undefined) {
        localStorage.clear();
        setIsAuthenticated(false);
        toast.error("Check your API or network");
      } else if (apiResponse.status === 200) {
        setIsAuthenticated(true);
      } else if (apiResponse.status === 401) {
        setIsAuthenticated(false);
      }
    } catch (error) {
      toast.error("Error fetching user details");
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      getLoggedInUserDetails();
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return <Loader />;
  }

  if (isAuthenticated === false) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};