import React from 'react'
import { Col } from 'reactstrap'

export default function GreetMessage({userDeatils}) {
    return (
        <>
            <Col lg={12}>
                <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                    <div className="flex-grow-1">
                        <h4 className="fs-16 mb-1">Good Morning, {userDeatils.firstName + " " + userDeatils.lastName}!</h4>
                        <p className="text-muted mb-0">Here's what's happening with your store today.</p>
                    </div>
                </div>
            </Col>
        </>
    )
}
