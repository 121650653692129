import classnames from "classnames";
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import SimpleBar from "simplebar-react";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { editNotificationAPI, getNotificationsAPI } from '../../apiConfig/apiCall';

export default function ManageNotifications() {

    const location = useLocation();
    const query = location.search.substring(1)||'All';

    const [isLoader, setIsLoader] = useState(true);
    const [originalNotifications, setOriginalNotifications] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const getNotification = async () => {
        try {
            const data = { type: '', notificationFor: '', unread: '', entryDate: '' };
            const apiResponse = await getNotificationsAPI(data);
            if (apiResponse.status === 200) {
                if (query === "All") {
                    setNotifications(apiResponse.data);
                } else {
                    const filteredData = apiResponse.data.filter((item) => item.type === query);
                    setNotifications(filteredData);
                }
                setOriginalNotifications(apiResponse.data);
            }
            else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsLoader(false);
        }
    }

    useEffect(() => {
        getNotification();
    }, [])


    // Other
    const [activeTabs, setActive] = useState("all");
    const [isLabelTab, setIsLabelTab] = useState("");
    const [isTypeTab, setIsTypeTab] = useState(query);
    const [emailinfo, setEmailinfo] = useState([]);
    const [displayCategory, setCategory] = useState("all");
    const [displaytype, settype] = useState("all");
    const [displaylabel, setLabel] = useState("all");

    const toggleTab = (type) => {
        setIsTypeTab(type);
        if (type === "All") {
            setNotifications(originalNotifications);
        } else {
            const filteredData = originalNotifications.filter((item) => item.type === type);
            setNotifications(filteredData);
        }
    };

    async function sidebarOpen(value, id, ele) {
        const element = document.getElementsByTagName('body')[0];
        element.classList.add(value);
        ele.closest("li").classList.remove("unread");

        try {
            const apiResponse = await editNotificationAPI([id]);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);
            }
            else if (apiResponse.status === 208) {
                console.log("")
            }
            else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    function sidebarClose(value) {
        const element = document.getElementsByTagName('body')[0];
        element.classList.remove(value);
    }


    const readAll = async (idArray) => {

        const ids = idArray.map((item) => item.id);
        console.log(ids);
        try {
            const apiResponse = await editNotificationAPI(ids);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);
                if (document.querySelectorAll(".message-list li.unread").length === 0) {
                    document.getElementById("unreadConversations").style.display = "block";
                    setTimeout(function () { document.getElementById("unreadConversations").style.display = "none"; }, 1000);
                }

                document.querySelectorAll(".message-list li.unread").forEach(function (element) {
                    if (element.classList.contains("unread")) { element.classList.remove("unread"); }
                });
            }
            else if (apiResponse.status === 208) {
                console.log("")
            }
            else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.log(error.message);
        }

    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={'Manage Notifications'} pageTitle={"Dashboard"} />

                    <Row>
                        <Col lg={12}>

                            <div className="email-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">

                                <div className="email-content minimal-border">
                                    <div className="p-4 pb-0">
                                        <div className="border-bottom border-bottom-dashed">
                                            <Row className="mt-n2 mb-3 mb-sm-0">
                                                <Col className="col-sm-auto order-1 d-block d-lg-none">
                                                    <button type="button" className="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn">
                                                        <i className="ri-menu-2-fill align-bottom"></i>
                                                    </button>
                                                </Col>
                                                <div className="col-sm order-3 order-sm-2">
                                                    <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">

                                                        <div className="vr align-self-center mx-2"></div>

                                                        <div className="cursor-pointer" style={{ textDecoration: 'underline' }} onClick={() => readAll(notifications)}> Mark all as Read </div>

                                                    </div>
                                                </div>
                                            </Row>

                                            <Row className="row align-items-end mt-3">
                                                <Col>
                                                    <div id="mail-filter-navlist">
                                                        <Nav className="nav nav-tabs nav-tabs-custom nav-success gap-1 text-center border-bottom-0" role="tablist">

                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames(
                                                                        { active: isTypeTab === "All" },
                                                                        "fw-semibold"
                                                                    )}
                                                                    onClick={() => {
                                                                        toggleTab('All');
                                                                    }}
                                                                    href="#"
                                                                >
                                                                    <i className="ri-inbox-fill align-bottom d-inline-block"> </i>
                                                                    <span className="ms-1 d-none d-sm-inline-block"> All</span>
                                                                </NavLink>
                                                            </NavItem>

                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames(
                                                                        { active: isTypeTab === "Notification" },
                                                                        "fw-semibold"
                                                                    )}
                                                                    onClick={() => {
                                                                        toggleTab("Notification");
                                                                    }}
                                                                    href="#"
                                                                >
                                                                    <i className="bx bx-bell text-success"></i>
                                                                    <span className="ms-1 d-none d-sm-inline-block"> Notification</span>
                                                                </NavLink>
                                                            </NavItem>

                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames(
                                                                        { active: isTypeTab === "Alert" },
                                                                        "fw-semibold"
                                                                    )}
                                                                    onClick={() => {
                                                                        toggleTab("Alert");
                                                                    }}
                                                                    href="#"
                                                                >
                                                                    <i className="bx bx-error-circle text-danger"></i>
                                                                    <span className="ms-1 d-none d-sm-inline-block">Alert</span>
                                                                </NavLink>
                                                            </NavItem>

                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames(
                                                                        { active: isTypeTab === "Log" },
                                                                        "fw-semibold"
                                                                    )}
                                                                    onClick={() => {
                                                                        toggleTab('Log');
                                                                    }}
                                                                    href="#"
                                                                >
                                                                    <i className="bx bx-history text-primary"></i>
                                                                    <span className="ms-1 d-none d-sm-inline-block"> Log</span>
                                                                </NavLink>
                                                            </NavItem>


                                                        </Nav>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="message-list-content mx-n4 px-4 message-list-scroll">
                                            {isLoader ? <div id="elmLoader">
                                                <div className="spinner-border text-primary avatar-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div> :
                                                <ul className="message-list" id="mail-list">
                                                    {
                                                        notifications.filter(({ category, type, label }) => displayCategory === category || (displayCategory === "all" && (displaytype === type || displaytype === 'all') && (displaylabel === label || displaylabel === 'all'))).map((item, key) => (

                                                            <li className={item.unread ? "unread" : null} key={key} id={item.forId}>
                                                                <div className="col-mail col-mail-1">
                                                                    <div className="form-check checkbox-wrapper-mail fs-4">

                                                                        {item.type === "Log" && <i className="bx bx-history text-primary"></i>}
                                                                        {item.type === "Notification" && <i className="bx bx-bell text-success"></i>}
                                                                        {item.type === "Alert" && <i className="bx bx-error-circle text-danger"></i>}

                                                                    </div>

                                                                    <Link to="#" className="title" onClick={(e) => { sidebarOpen("email-detail-show", item.id, e.target); setEmailinfo(item); }}>{item.subject} </Link>

                                                                </div>
                                                                <div className="col-mail col-mail-2" onClick={(e) => { sidebarOpen("email-detail-show", item.id, e.target); setEmailinfo(item); }}>
                                                                    <Link to="#" className="subject">
                                                                        {item.badge ? <span className="subject-title me-2">{item.badge}-</span> : null}
                                                                        <span className="teaser">{item.overView}</span>
                                                                    </Link>
                                                                    <div className="date w-auto">
                                                                        {new Date(item.entryDate).toLocaleString("en-US", {
                                                                            day: "2-digit",
                                                                            month: "short",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            hour12: true,
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        ))}
                                                </ul>}
                                        </div>
                                    </div>
                                </div>

                                <div className="email-detail-content minimal-border">
                                    <div className="p-4 d-flex flex-column h-100">
                                        <div className="pb-4 border-bottom border-bottom-dashed">
                                            <Row>
                                                <Col className="col">
                                                    <div className="">
                                                        <button type="button" className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email material-shadow-none" onClick={() => sidebarClose("email-detail-show")}>
                                                            <i className="ri-close-fill align-bottom"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <SimpleBar className="mx-n4 px-4 email-detail-content-scroll">
                                            <div className="mt-4 mb-3">
                                                <h5 className="fw-bold email-subject-title">{emailinfo.subject}</h5>
                                            </div>
                                            <div>
                                                <p>{emailinfo.overView}</p>
                                                <p className="fw-bold">
                                                    {new Date(emailinfo.entryDate).toLocaleString("en-US", {
                                                        day: "2-digit",
                                                        month: "short",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true,
                                                    })}
                                                </p>
                                            </div>
                                        </SimpleBar>
                                    </div>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}