import { commonRequest } from "./axiosConfig";

/**
 * @DESC Authentication Api Route & Other (User Details, Client Company Detail, Notification, Support Ticket);
 */
// Web URL
const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;

export const loginUser = async (data) => {
    return commonRequest("POST", `${AUTH_URL}/auth/login`, data);
};

export const verifyOtp = async (data) => {
    return commonRequest("POST", `${AUTH_URL}/auth/verify-otp`, data);
};

export const getLoggedInUserAPI = async () => {
    return await commonRequest("GET", `${AUTH_URL}/user/get-user`);
}
export const forgotPassword = async (data) => {
    return commonRequest("PUT", `${AUTH_URL}/auth/reset-password`, data);
};

export const changePasswordApi = async (data) => {
    return await commonRequest("PUT", `${AUTH_URL}/user/update-password`, data);
}

export const logoutAPI = async () => {
    return await commonRequest("POST", `${AUTH_URL}/auth/logout`);
}

export const logoutAllAPI = async () => {
    return await commonRequest("POST", `${AUTH_URL}/auth/logout-all`);
}

// Notification API
export const getNotificationsAPI = async (DATA) => {
    return await commonRequest("POST", `${AUTH_URL}/notifications/notifications-list`, DATA);
}
export const editNotificationAPI = async (DATA) => {
    return await commonRequest("PUT", `${AUTH_URL}/notifications/edit-notification`, DATA);
}

// Support Tickets API
export const getSportTicketListAPI = async () => {
    return await commonRequest("GET", `${AUTH_URL}/support/support-ticket-list`);
}
export const getSingleSportTicketListAPI = async (ID) => {
    return await commonRequest("GET", `${AUTH_URL}/support/get-support-ticket/${ID}`);
}
export const addSportTicketAPI = async (DATA) => {
    return await commonRequest("POST", `${AUTH_URL}/support/add-support-ticket`, DATA);
}
export const deleteSportTicketAPI = async (ID) => {
    return await commonRequest("DELETE", `${AUTH_URL}/support/delete-support-ticket/${ID}`);
}
export const addSupportCommentAPI = async (DATA) => {
    return await commonRequest("PUT", `${AUTH_URL}/support/comments-support-ticket`, DATA, {
        'Content-Type': 'multipart/form-data'
    });
}
export const reOpenSupportTicketAPI = async (ID) => {
    return await commonRequest("PUT", `${AUTH_URL}/support/reopen-support-ticket/${ID}`);
}

// Manage License API
export const getClientLicenseListAPI = async (CLIENT_ID) => {
    return await commonRequest("GET", `${AUTH_URL}/license/client-license-list/${CLIENT_ID}`);
} 