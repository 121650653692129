import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import withRouter from "../../Components/Common/withRouter";
import authLogoXzlenz from '../../assets/xzlenzImages/xzlenz-login.jpg';
import { forgotPassword, loginUser } from '../../apiConfig/apiCall';
import Cookies from "js-cookie";
import { toast } from 'react-toastify';

const ForgotPassword = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get('x-http-core');
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate]);

    useEffect(() => {
        const canvas = document.getElementById('universe');
        const ctx = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        let stars = [];
        const numStars = 150;
        for (let i = 0; i < numStars; i++) {
            const isFast = Math.random() > 0.7;
            const isSuperFast = Math.random() > 0.9;
            const angle = isSuperFast && Math.random() > 0.5 ? Math.PI / 4 : 0;

            const speed = isSuperFast
                ? Math.random() * 5 + 3
                : isFast
                    ? Math.random() * 2 + 1
                    : Math.random() * 0.5 + 0.25;

            stars.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: Math.random() * 2,
                speedX: speed * Math.cos(angle),
                speedY: speed * Math.sin(angle),
            });
        }

        function animate() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = 'white';

            stars.forEach(star => {
                ctx.beginPath();
                ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
                ctx.fill();
                ctx.closePath();

                star.x += star.speedX;
                star.y += star.speedY;

                if (star.x < 0) star.x = canvas.width;
                if (star.x > canvas.width) star.x = 0;
                if (star.y < 0) star.y = canvas.height;
                if (star.y > canvas.height) star.y = 0;
            });

            requestAnimationFrame(animate);
        }

        animate();

        window.addEventListener('resize', () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        });

        return () => {
            window.removeEventListener('resize', () => { });
        };
    }, []);

    const [inputValues, setInputValues] = useState({ username: "", email: "" });
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);

            const { username, email } = inputValues;

            if (username === "") {
                toast.error("SSO ID is required");
                setLoading(false);
                return;
            } else if (email === "") {
                toast.error("Email is required");
                setLoading(false);
                return;
            }

            const data = { username, emailId: email };

            const apiResponse = await forgotPassword(data);

            if (apiResponse === undefined) {
                toast.error("API not working");
                setLoading(false);
            } else if (apiResponse.status === 200) {
                navigate('/login');
                toast.success("Successfully reset password");
            } else {
                toast.error("Invalid details");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid className="login-page">
            <div className="auth-content">
                <Form>
                    <div className="logo">
                        <img className="img-fluid" src={authLogoXzlenz} alt="Logo" />
                    </div>
                    <h1 className="title py-2">Reset Password</h1>

                    <FormGroup floating>
                        <Input id="username" name="username" placeholder="SSO ID" type="text" value={inputValues.username} onChange={handleChange} />
                        <Label for="username" style={{ fontSize: '.9rem' }}>SSO ID</Label>
                    </FormGroup>

                    <FormGroup floating>
                        <Input id="email" name="email" placeholder="Email" type="text" value={inputValues.email} onChange={handleChange} />
                        <Label for="email" style={{ fontSize: '.9rem' }}>Email ID</Label>
                    </FormGroup>

                    <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit" onClick={handleSubmit} disabled={loading}
                            style={{ background: "#2f55d4" }}
                        >
                            {loading ? (
                                <Spinner size="sm" className='me-2' />
                            ) : (
                                "Reset Password"
                            )}
                        </Button>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className='d-flex align-items-center'>
                            <p className="mb-0 me-2">Remember your password ?</p>
                            <Link to="/login" className="text-dark text-decoration-underline">
                                Login
                            </Link>
                        </div>
                    </div>

                    <div className="text-center mt-3">
                        <p className="text-muted mb-2 fs-5">XzLenz</p>
                        <p className="mb-0 text-muted fs-5">Compliance Excellence, Unveiled</p>
                    </div>
                </Form>
            </div>
            <div className="universe">
                <div className="universe-container">
                    <canvas id="universe"></canvas>
                </div>
            </div>
        </Container>
    );
};

export default withRouter(ForgotPassword);