import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

//import images

//SimpleBar
import SimpleBar from "simplebar-react";
import { editNotificationAPI, getNotificationsAPI } from '../../apiConfig/apiCall';
import { toast } from 'react-toastify';

const NotificationDropdown = () => {
    //Dropdown Toggle
    const [newNotification, setNewNotification] = useState();
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    //Tab 
    const [activeTab, setActiveTab] = useState('All');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const [notificationAll, setNotificationAll] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notificationAlert, setNotificationAlert] = useState([]);

    const [selectedNotification, setSelectedNotification] = useState(null);
    const [isDetailVisible, setIsDetailVisible] = useState(false);

    const handleNotificationClick = async(notification) => {
        setSelectedNotification(notification);
        setIsDetailVisible(true);

        try {
            const apiResponse = await editNotificationAPI([notification.id]);
            if (apiResponse.status === 200) {
                toast.success(apiResponse.data);
                getNotification();
            }
            else if (apiResponse.status === 208) {
                console.log("")
            }
            else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const closeDetailView = () => {
        setIsDetailVisible(false);
        setSelectedNotification(null);
    };

    const getNotification = async () => {
        try {
            const data = {
                type: '',
                notificationFor: '',
                unread: true,
                entryDate: ''
            }
            const apiResponse = await getNotificationsAPI(data);

            if (apiResponse.status === 200) {
                setNotificationAll(apiResponse.data);
                setNotifications(
                    apiResponse.data.filter((item) => item.type === "Notification")
                );
                setNotificationAlert(
                    apiResponse.data.filter((item) => item.type === "Alert")
                );
                setNewNotification(apiResponse.data.length);
            }

            else {
                toast.error(apiResponse.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        getNotification();
    }, []);

    useEffect(() => {
        if (location.pathname === '/notifications') {
            setIsNotificationDropdown(false);
        }
    }, [location.pathname]);

    return (
        <>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{newNotification}<span
                            className="visually-hidden">unread messages</span></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge bg-light-subtle text-body fs-13"> {newNotification} New</span>
                                </div>
                            </Row>
                        </div>

                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 'All' })}
                                        onClick={() => { toggleTab('All'); }}
                                    >
                                        All
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 'Notification' })}
                                        onClick={() => { toggleTab('Notification'); }}
                                    >
                                        Notification
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === 'Alert' })}
                                        onClick={() => { toggleTab('Alert'); }}
                                    >
                                        Alert
                                    </NavLink>
                                </NavItem>


                            </Nav>
                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>

                        <TabPane tabId="All" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                {notificationAll.length === 0 ? (
                                    <div className="text-center text-muted py-3">
                                        No Notifications
                                    </div>
                                ) : (
                                    notificationAll.slice(0, 5).map((item, index) => (
                                        <div
                                            className="text-reset notification-item d-block dropdown-item position-relative"
                                            key={index}
                                            onClick={() => handleNotificationClick(item)}
                                        >
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                        <i className="bx bx-badge-check"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#" className="stretched-link">
                                                        <h6 className="mt-0 mb-2 lh-base">{item.subject}</h6>
                                                    </Link>
                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span>
                                                            <i className="mdi mdi-clock-outline"></i>{' '}
                                                            {new Date(item.entryDate).toLocaleString('en-IN', {
                                                                dateStyle: 'short',
                                                                timeStyle: 'short',
                                                            })}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                {notificationAll.length > 0 && (
                                    <div className="my-3 text-center">
                                        <Link
                                            to={'/notifications?All'}
                                            className="btn btn-soft-success waves-effect waves-light"
                                        >
                                            View All Notifications <i className="ri-arrow-right-line align-middle"></i>
                                        </Link>
                                    </div>
                                )}
                            </SimpleBar>
                        </TabPane>

                        <TabPane tabId="Notification" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">

                                {notifications.length === 0 ? (
                                    <div className="text-center text-muted py-3">
                                        No Notifications
                                    </div>
                                ) : (
                                    notifications.slice(0, 5).map((item, index) => (
                                        <div className="text-reset notification-item d-block dropdown-item position-relative" key={index}
                                            onClick={() => handleNotificationClick(item)}
                                        >
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title bg-success-subtle text-info rounded-circle fs-16">
                                                        <i className="bx bx-bell text-success"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#" className="stretched-link">
                                                        <h6 className="mt-0 mb-2 lh-base">
                                                            {item.subject}
                                                        </h6>
                                                    </Link>
                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span>
                                                            <i className="mdi mdi-clock-outline"></i>{' '}
                                                            {new Date(item.entryDate).toLocaleString('en-IN', {
                                                                dateStyle: 'short',
                                                                timeStyle: 'short',
                                                            })}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}

                                {notifications.length > 0 && (
                                    <div className="my-3 text-center">
                                        <Link to={'/notifications?Notification'} className="btn btn-soft-success waves-effect waves-light">View Notifications <i className="ri-arrow-right-line align-middle"></i></Link>
                                    </div>
                                )}

                            </SimpleBar>

                        </TabPane>

                        <TabPane tabId="Alert" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">



                                {notificationAlert.length === 0 ? (
                                    <div className="text-center text-muted py-3">
                                        No Alerts
                                    </div>
                                ) : (
                                    notificationAlert.slice(0, 5).map((item, index) => (
                                        <div className="text-reset notification-item d-block dropdown-item position-relative" key={index}
                                            onClick={() => handleNotificationClick(item)}
                                        >
                                            <div className="d-flex">
                                                <div className="avatar-xs me-3">
                                                    <span className="avatar-title bg-danger-subtle text-info rounded-circle fs-16">
                                                        <i className="bx bx-error-circle text-danger"></i>
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Link to="#" className="stretched-link">
                                                        <h6 className="mt-0 mb-2 lh-base">
                                                            {item.subject}
                                                        </h6>
                                                    </Link>
                                                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span>
                                                            <i className="mdi mdi-clock-outline"></i>{' '}
                                                            {new Date(item.entryDate).toLocaleString('en-IN', {
                                                                dateStyle: 'short',
                                                                timeStyle: 'short',
                                                            })}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}

                                {notificationAlert.length > 0 && (
                                    <div className="my-3 text-center">
                                        <Link to={'/notifications?Alert'} className="btn btn-soft-success waves-effect waves-light">View Alerts <i className="ri-arrow-right-line align-middle"></i></Link>
                                    </div>
                                )}

                            </SimpleBar>

                        </TabPane>

                    </TabContent>


                    {isDetailVisible && (
                        <div
                            style={{
                                height: "200px",
                                width: "100%",
                                background: "#fff",
                                position: "absolute",
                                right: "20rem",
                                top: "0",
                                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                overflowX: 'hidden',
                                overflowY: 'scroll'
                            }}
                            className='verticleScrollbar'
                        >

                            <button
                                type="button"
                                className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email material-shadow-none"
                                onClick={closeDetailView}
                            >
                                <i className="ri-close-fill align-bottom"></i>
                            </button>

                            <div className="p-3">
                                <div className="mt-4 mb-3">
                                    <h5 className="fw-bold email-subject-title">{selectedNotification.subject}</h5>
                                </div>
                                <div>
                                    <p>{selectedNotification.overView}</p>
                                    <p className="fw-medium mb-0">
                                        {new Date(selectedNotification.entryDate).toLocaleString("en-US", {
                                            day: "2-digit",
                                            month: "short",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: true,
                                        })}
                                    </p>
                                </div>
                            </div>

                        </div>
                    )}

                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default NotificationDropdown;