import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const Navdata = () => {
  const navigate = useNavigate();
  const [isDashboard, setIsDashboard] = useState(false);
  const [isManageNotification, setIsManageNotification] = useState(false);
  const [isManageSupport, setIsManageSupport] = useState(false);
  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") setIsDashboard(false);
    if (isCurrentState !== "ManageNotification") setIsManageNotification(false);
    if (isCurrentState !== "ManageSupport") setIsManageSupport(false);
  }, [
    isDashboard,
    isManageNotification,
    isManageSupport,
    navigate,
  ]);


  const menuItems = [
    { label: "Menu", isHeader: true},
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "bx bxs-dashboard",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: (e) => {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIsCurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "manageNotification",
      label: "Manage Notification",
      icon: "bx bxs-bell",
      link: "/notifications",
      stateVariables: isManageNotification,
      click: function (e) {
        e.preventDefault();
        setIsManageNotification(!isManageNotification);
        setIsCurrentState("ManageNotification");
        updateIconSidebar(e);
      },
    },
    {
      id: "manageSupport",
      label: "Manage Support",
      icon: "bx bx-support",
      link: "/support",
      stateVariables: isManageSupport,
      click: function (e) {
        e.preventDefault();
        setIsManageSupport(!isManageSupport);
        setIsCurrentState("ManageSupport");
        updateIconSidebar(e);
      },
    },
  ];


  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;