import { createSlice } from "@reduxjs/toolkit";
import { getSportTicketDetail } from "./thunk";

const getSportTicketDetailsSlice = createSlice({
    name: "getSportTicketDetailsSlice",
    initialState: {
        isLoading: false,
        isError: false,
        data: [],
    },
    extraReducers: (builder) => {
        builder.addCase(getSportTicketDetail.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(getSportTicketDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getSportTicketDetail.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });

    }
});

export default getSportTicketDetailsSlice.reducer;