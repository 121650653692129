import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Error from '../../Components/Common/Error';
import Loader from '../../Components/Common/Loader';
import SupportTicketTable from '../../Components/Support/SupportTicketTable';
import { getSportTicketList } from '../../slices/GetSupportTickets/thunk';

export default function ManageSupport() {

    const dispatch = useDispatch();
    const { isLoading, isError, data } = useSelector(state => state.SupportTickets);

    useEffect(() => {
        dispatch(getSportTicketList());
    }, [dispatch]);

    if (isLoading) return <Loader />

    if (isError) return <Error />

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={'Manage Support'} pageTitle={"Dashboard"} />

                    <Row>
                        <Col lg={12}>
                            <SupportTicketTable data={data} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
