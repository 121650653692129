import React from 'react';
import './assets/scss/themes.scss';
import Route from './Routes';
import Toaster from './Components/Common/Toaster'

function App() {
  return (
    <React.Fragment>
      <Toaster />
      <Route />
    </React.Fragment>
  );
}

export default App;