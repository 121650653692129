import React from 'react';
import { Container } from 'reactstrap';
import NOT_FOUND_IMAGE from '../../assets/xzlenzImages/notfound.jpg';
import { Link } from 'react-router-dom';
export default function NotFound() {
    return (
        <>
            <Container fluid className='p-0 m-0'>
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh', background: '#180002' }}>
                    <img src={NOT_FOUND_IMAGE} alt="..." style={{ width: '50vw', height: '60vh' }} />
                    <Link to={"/"} style={{color: '#fff', fontSize: '1.2rem', textDecoration: 'underline'}}>Back to home</Link>
                </div>
            </Container>
        </>
    )
}