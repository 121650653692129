import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from 'reactstrap';
import navdata from "../LayoutMenuData";
import withRouter from "../../Components/Common/withRouter";

const VerticalLayout = (props) => {

    const [layoutType, setLayoutType] = useState('vertical');
    const [leftsidbarSizeType, setLeftsidbarSizeType] = useState('lg');
    const [sidebarVisibilitytype, setSidebarVisibilitytype] = useState('show');

    const navData = navdata().props.children;
    const path = props.router.location.pathname;

    const resizeSidebarMenu = useCallback(() => {
        const windowSize = document.documentElement.clientWidth;
        const layout = document.documentElement.getAttribute("data-layout");
        const hamburgerIcon = document.querySelector(".hamburger-icon");

        const setSidebarSize = (size) => document.documentElement.setAttribute("data-sidebar-size", size);

        if (windowSize >= 1025) {
            if (["vertical", "semibox"].includes(layout)) setSidebarSize(leftsidbarSizeType);
            hamburgerIcon?.classList.toggle("open", !["show", "vertical", "twocolumn"].includes(sidebarVisibilitytype));
        } else if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("twocolumn-panel");
            if (["vertical", "semibox"].includes(layout)) setSidebarSize("sm");
            hamburgerIcon?.classList.add("open");
        } else {
            document.body.classList.remove("vertical-sidebar-enable");
            if (layout !== "horizontal") setSidebarSize("lg");
            hamburgerIcon?.classList.add("open");
        }
    }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);

    useEffect(() => {
        window.addEventListener("resize", resizeSidebarMenu, true);
    }, [resizeSidebarMenu]);

    useEffect(() => {
        const initMenu = () => {
            const pathName = path;
            const items = Array.from(document.getElementById("navbar-nav").getElementsByTagName("a"));
            removeActivation(items);
            const matchingMenuItem = items.find(x => x.pathname === pathName);
            if (matchingMenuItem) activateParentDropdown(matchingMenuItem);
        };

        if (layoutType === "vertical") initMenu();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [path, layoutType]);

    const activateParentDropdown = (item) => {
        item.classList.add("active");

        let parentCollapseDiv = item.closest(".collapse.menu-dropdown");
        while (parentCollapseDiv) {
            parentCollapseDiv.classList.add("show");
            const parentLink = parentCollapseDiv.previousElementSibling;
            parentLink?.classList.add("active");
            parentLink?.setAttribute("aria-expanded", "true");
            parentCollapseDiv = parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown");
        }
    };

    const removeActivation = (items) => {
        items.forEach(item => {
            item.classList.remove("active");
            item.setAttribute("aria-expanded", false);
            item.nextElementSibling?.classList.remove("show");
        });
    };

    const renderNavItems = (items, isSubMenu = false) => (
        items.map((item, key) => (
            <React.Fragment key={key}>
                {item.isHeader ? (
                    <li className="menu-title"><span data-key="t-menu">{item.label}</span></li>
                ) : item.subItems ? (
                    <li className="nav-item">
                        <Link
                            onClick={item.click}
                            className="nav-link menu-link"
                            to={item.link || "/#"}
                            data-bs-toggle="collapse"
                        >
                            {!isSubMenu && <i className={item.icon}></i>}
                            <span data-key="t-apps">{item.label}</span>
                            {item.badgeName && <span className={`badge badge-pill bg-${item.badgeColor}`} data-key="t-new">{item.badgeName}</span>}
                        </Link>
                        <Collapse className="menu-dropdown" isOpen={item.stateVariables} id="sidebarApps">
                            <ul className="nav nav-sm flex-column">
                                {renderNavItems(item.subItems, true)}
                            </ul>
                        </Collapse>
                    </li>
                ) : (
                    <li className="nav-item">
                        <Link className="nav-link menu-link" to={item.link || "/#"}>
                            {!isSubMenu && <i className={item.icon}></i>}
                            <span>{item.label}</span>
                            {item.badgeName && <span className={`badge badge-pill bg-${item.badgeColor}`} data-key="t-new">{item.badgeName}</span>}
                        </Link>
                    </li>
                )}
            </React.Fragment>
        ))
    );

    return <React.Fragment>{renderNavItems(navData)}</React.Fragment>;
};

VerticalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(VerticalLayout);