import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import img from "../../../assets/images/companies/img-4.png";
import { reOpenSupportTicketAPI } from '../../../apiConfig/apiCall';
import { toast } from 'react-toastify';

const Section = ({ ticketDetails }) => {

    const reOpenTicket = async () => {
        try {
            const apiResponse = await reOpenSupportTicketAPI(ticketDetails.id);
            if (apiResponse.status === 200) {
                toast.success("Ticket reopen successfully");
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <React.Fragment>
            <Col lg={12}>
                <Card className="mt-n4 mx-n4 mb-n5">
                    <div className="bg-warning-subtle">
                        <CardBody className="pb-4 mb-5">
                            <Row>
                                <div className="col-md">
                                    <Row className="align-items-center">
                                        <div className="col-md-auto">
                                            <div className="avatar-md mb-md-0 mb-4">
                                                <div className="avatar-title bg-white rounded-circle">
                                                    <img src={img} alt="" className="avatar-sm" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <h4 className="fw-semibold" id="ticket-title">#{ticketDetails.id} - {ticketDetails.subject}</h4>
                                            <div className="hstack gap-3 flex-wrap">
                                                <div className="text-muted"><i className="ri-building-line align-bottom me-1"></i> <span id="ticket-client">{ticketDetails.clientCompanyName}</span></div>
                                                <div className="vr"></div>
                                                <div className="text-muted">Create Date : <span className="fw-medium" id="create-date">{ticketDetails.entryDate}</span></div>
                                                <div className="vr"></div>
                                                <div className="badge rounded-pill bg-info fs-12" id="ticket-status">{ticketDetails.ticketType}</div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <div className="col-md-auto mt-md-0 mt-4">
                                    <div className="hstack gap-1 flex-wrap">

                                        <Button color='primary' className='btn-sm' onClick={reOpenTicket}>Repoen Ticket</Button>

                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Section;