import React from 'react'

export default function Error() {
    return (
        <>
            <div className="error">
                <h1>Something went wrong !!!</h1>
            </div>
        </>
    )
}
