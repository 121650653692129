import { combineReducers } from "redux";

import LayoutReducer from "./layouts/reducer";
import UserDetailsReducer from "./UserDeatils/dashboardWidgetDataSlice";
import SupportTicketsReducer from "./GetSupportTickets/reducer";
import SupportTicketDetailsReducer from "./GetSupportTicketDetails/reducer";
import LicenseListReducer from "./LicenseList/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    UserDetails: UserDetailsReducer,
    SupportTickets: SupportTicketsReducer,
    SupportTicketDetails: SupportTicketDetailsReducer,

    LicenseList: LicenseListReducer,
});

export default rootReducer;