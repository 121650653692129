import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteSportTicketAPI } from '../../apiConfig/apiCall';
import BasicTable from './BasicTable';
import { useDispatch } from 'react-redux';
import { getSportTicketList } from '../../slices/GetSupportTickets/thunk';

const SupportTicketTable = ({ data }) => {

    const dispatch = useDispatch();

    const columns = [
        {
            header: "ID",
            accessorKey: "id",
            cell: ({ getValue }) => (
                <span className="fw-semibold">{getValue()}</span>
            ),
        },
        {
            header: "Name",
            accessorKey: "name",
            enableSorting: false,
        },
        {
            header: "Subject",
            accessorKey: "subject",
        },
        {
            header: "Ticket Type",
            accessorKey: "ticketType",
        },
        {
            header: "Priority",
            accessorKey: "priority",
        },
        {
            header: "Status",
            accessorKey: "status",
        },
        {
            header: "Entry Date",
            accessorKey: "entryDate",
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: ({ row }) => (
                <div className="d-flex gap-2">
                    <div onClick={() => handleDelete(row.original.id)} className="text-danger fs-5 cursor-pointer">
                        <i className="bx bxs-trash-alt"></i>
                    </div>

                    <Link to={`/suppot-ticket/${row.original.id}`} className="text-primary fs-5 cursor-pointer">
                        <i className="bx bxs-show"></i>
                    </Link>
                </div>

            ),
            enableSorting: false,
        },
    ];

    const handleDelete = (id) => {
        Swal.fire({
            width: '20rem',
            padding: '0 0 1rem 0',
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSportTicketAPI(id)
                    .then((response) => {
                        Swal.fire({
                            title: "Deleted!",
                            text: response.data,
                            icon: "success",
                            width: '20rem',
                            padding: '0 0 1rem 0',
                        });
                        dispatch(getSportTicketList());
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: "Error!",
                            text: error.response?.data?.message || error.message,
                            icon: "error",
                            width: '20rem',
                            padding: '0 0 1rem 0',
                        });
                    });
            }
        });
    };

    return (
        <>

            <BasicTable
                columns={columns}
                data={data}
                customPageSize={10}
            />

        </>
    );
};

export default SupportTicketTable;