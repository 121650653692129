export const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
  TWOCOLUMN: "twocolumn",
  SEMIBOX:"semibox"
};

export const layoutTheme = {
  DEFAULT : "default",
  MINIMAL : "minimal",
  SAAS :  "saas",
  CORPORATE : "corporate",
  GALAXY : "galaxy",
  MATERIAL : "material",
  CREATIVE : "creative",
  MODERN : "modern",
  INTERACTIVE : "interactive",
  CLASSIC : "classic",
  VINTAGE : "vintage",
};

export const layoutThemeColor = {
  DEFAULT : "default",
  GREEN : "green",
  PURPLE : "purple",
  BLUE : "blue",
  
};

export const layoutModeTypes = {
  LIGHTMODE: "light",
  DARKMODE: "dark",
};

export const leftSidebarTypes = {
  LIGHT: "light",
  DARK: "dark",
  GRADIENT: "gradient",
  GRADIENT_2: "gradient-2",
  GRADIENT_3: "gradient-3",
  GRADIENT_4: "gradient-4",
};

export const layoutWidthTypes = {
  FLUID: "lg",
  BOXED: "boxed",
};

export const layoutPositionTypes = {
  FIXED: "fixed",
  SCROLLABLE: "scrollable",
};

export const topbarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
};

export const leftsidbarSizeTypes = {
  DEFAULT: "lg",
  COMPACT: "md",
  SMALLICON: "sm",
  SMALLHOVER: "sm-hover"
};

export const leftSidebarViewTypes = {
  DEFAULT: "default",
  DETACHED : "detached"
};

export const leftSidebarImageTypes = {
  NONE: "none",
  IMG1: "img-1",
  IMG2: "img-2",
  IMG3: "img-3",
  IMG4: "img-4",  
};

export const preloaderTypes = {
  ENABLE: "enable",
  DISABLE: "disable"
}

export const sidebarVisibilitytypes = {
  SHOW: "show",
  HIDDEN: "hidden"
}