// All Routes
export const HOME_URL = "/";
export const DASHBOARD_URL = "/dashboard";
export const NOTIFICATION_URL = "/notifications";
export const SUPPORT_URL = "/support";
export const VIEW_SUPPORT_URL = "/suppot-ticket/:id";
export const NOT_FOUND_URL = "/not-found";
export const LOGIN_URL = "/login";
export const FORGOT_PASSWORD_URL = "/forgot-password";

// Other 1
