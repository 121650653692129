import Cookies from 'js-cookie';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Error from '../../Components/Common/Error';
import Loader from '../../Components/Common/Loader';
import CompanyCard from "../../Components/Dashboard/CompanyCard";
import GreetMessage from "../../Components/Dashboard/GreetMessage";
import { getLoggedInUserDetails } from "../../slices/UserDeatils/thunk";
import { getClientLicenseList } from '../../slices/LicenseList/thunk';
const Dashboard = () => {

  const dispatch = useDispatch();
  const { isLoading, isError, data } = useSelector(state => state.UserDetails);

  const { isLoading: licenseLoading, isError: licenseError, data: licenseData } = useSelector(state => state.LicenseList);

  useEffect(() => {
    dispatch(getLoggedInUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (data.clientCompanyId) {
      dispatch(getClientLicenseList(userDeatils.clientCompanyId));
    }
  }, [dispatch, data.clientCompanyId])

  if (isLoading || licenseLoading) return <Loader />

  if (isError || licenseError) return <Error />

  const userDeatils = data;

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-3 pb-1">

            <GreetMessage userDeatils={userDeatils} />
            <CompanyCard licenseData={licenseData}/>

          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;